export enum EApiParam {
  UNITTYPEIDS = "unitTypeIds",
  PAGE = "page",
  LIMIT = "limit",
  PROMOTION_PAGE = "promotion_page",
  PROMOTION_LIMIT = "promotion_limit",
  KEYWORD = "keyword",
  PROJECT_SLUG = "projectSlug",
  START_DATE = "startDate",
  END_DATE = "endDate",
  LOCATIONS = "locations",
  SECTION_ID = "sectionId",
  TYPE = "type",
  PRICE_PAGE = "price_page",
  PRICE_LIMIT = "price_limit",
  DISTINCT = "distinct",
  SORT_BY = "sortBy",
  SORT = "sort",
}
